import axios, { AxiosRequestConfig } from "axios";
import queryString from "query-string";

// for debugging
// export const BASE_URL = "https://uysot.oracle.softex.uz";
export const BASE_URL = "https://service.app.uysot.uz";
export const BASE_URL_VISUAL = `https://srv.showroom.app.uysot.uz`;

const addToken = (config: AxiosRequestConfig) => {
  config.auth = {
    username: String(queryString.parse(window.location.search)?.login),
    password: String(queryString.parse(window.location.search)?.parol),
  };

  return config;
};

const addTokenVisual = (config: AxiosRequestConfig) => {
  config.headers!["X-Auth"] = String(
    queryString.parse(window.location.search)?.token
  );
  return config;
};

export const $api = axios.create({
  baseURL: BASE_URL,
});

export const $apiVisual = axios.create({
  baseURL: BASE_URL_VISUAL,
});

$api.interceptors.request.use(addToken);
$apiVisual.interceptors.request.use(addTokenVisual);
